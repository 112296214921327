import { createTheme, responsiveFontSizes } from "@mui/material/styles"
import { blueGrey } from "@mui/material/colors"

var theme = createTheme({
  palette: {
    primary: {
      main: "#402e2a"
    },
    secondary: {
      main: "#727b65",
      light: "#f09424"
    },
    blueGrey: blueGrey,
    background: {
      cream: "#f5f0ea",
      darkCream: "#E4D8C8",
      alt: "#f6f6e9",
      sponsor: "#e9ddd8"
    }
  },
  typography: {
    h1: {
      fontSize: "3.5rem"
    },
    h2: {
      fontSize: "2.8rem"
    },
    h3: {
      fontSize: "2.1rem"
    },
    h4: {
      fontSize: "1.7rem"
    },
    h5: {
      fontSize: "1.4rem"
    },
    h6: {
      fontSize: "1.2rem"
    }
  }
})

theme = responsiveFontSizes(theme, {
  breakpoints: ["sm", "md"],
  factor: 3
})

export default theme
