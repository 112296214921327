import { Grid, Container } from "@mui/material"
import { QuickLinks } from "./quickLinks"
import { SocialLinks } from "./socialLinks"
import { Us } from "./us"

export default function Content() {
  return (
    <Container>
      <Grid sx={{ mt: 1, mb: 3 }} container item spacing={4}>
        <Grid
          item
          sx={{ display: "flex", justifyContent: "center" }}
          xs={12}
          sm={4}
          md={3}
          order="1"
        >
          <QuickLinks />
        </Grid>
        <Grid
          item
          sx={{ display: "flex", justifyContent: "center" }}
          xs={12}
          sm={4}
          md={6}
          order={{ xs: 3, sm: 2 }}
        >
          <Us />
        </Grid>
        <Grid
          item
          sx={{ display: "flex", justifyContent: "center" }}
          xs={12}
          sm={4}
          md={3}
          order={{ xs: 2, sm: 3 }}
        >
          <SocialLinks />
        </Grid>
      </Grid>
    </Container>
  )
}
