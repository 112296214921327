import { Stack, Typography, Box } from "@mui/material"

export function Us() {
  return (
    <Stack direction="column" alignItems="center">
      <Box
        component="img"
        sx={{
          display: "block",
          width: "20%",
          height: "auto",
          mb: 1.5
        }}
        alt="LIMIT logo"
        src={`${process.env.PUBLIC_URL}/old_logo.png`}
      />
      <Typography
        sx={{ mb: 2 }}
        align="center"
        variant="h3"
        color="white"
        gutterBottom
      >
        LIMIT
      </Typography>
      <Typography align="center" variant="subtitle1">
        By the students and research scholars of
        <br />
        Indian Statistical Institute, Bangalore Centre
      </Typography>
    </Stack>
  )
}
