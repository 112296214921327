import Stack from "@mui/material/Stack"
import IconButton from "@mui/material/IconButton"
import Link from "@mui/material/Link"
import { SLS } from "./itemStatic"

export function SocialLinks() {
  return (
    <Stack alignItems="center">
      {/* <Typography color="grey.200" align="right" sx={{ mb: 1 }}>
        Follow us here!
      </Typography> */}
      <Stack
        direction={{ xs: "row", sm: "column" }}
        justifyContent="right"
        spacing={{ xs: 1, sm: -0.5 }}
      >
        {SLS.map((link, index) => {
          return (
            <Link
              component={IconButton}
              aria-label={`Link to LIMIT ${link.name} handle`}
              key={index}
              href={link.to}
              sx={{ color: "inherit" }}
              align="center"
            >
              {link.icon}
            </Link>
          )
        })}
      </Stack>
    </Stack>
  )
}
